.container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}

.header {
  font-family: DM Serif Display;
  font-size: 54px;
  font-weight: 400;
  line-height: 64px;
  color: var(--primary-color-dark);
  text-align: center;
  // margin-top: 3rem;
}

.imgContainer {
  height: 600px;
  width: 100%;
  background-color: grey;
  display: grid;
  place-content: center;
  justify-items: center;
}

.btn {
  height: 48px !important;
  width: 201px !important;
  border-radius: 0px !important;
  background-color: #fff !important;
  text-transform: uppercase !important;
  font-family: Inter !important;
  font-size: 11px !important;
  font-weight: 700 !important;
  line-height: 16px !important;
  color: rgb(0, 0, 0) !important;
  border: 1px solid var(--primary-color) !important;
  margin-top: 3rem !important;
  border: none !important;
  box-shadow: none !important;
}

@media screen and (max-width: 550px) {
  .header {
    font-size: 20px;
  }
}
