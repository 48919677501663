.header {
  // height: 469px;
  width: 288px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 54px;
  font-weight: 400;
  line-height: 63px;
  letter-spacing: -1.5px;
  p {
    color: #fff;
    > span:first-child {
      font-family: DM Serif Display;
      font-style: italic;
    }
    > span:last-child {
    }
  }
}

@media screen and (max-width: 835px) {
  .header {
    font-size: 44px;
    line-height: 51px;
    height: 366px;
    width: 225px;
  }
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 550px) {
  .header {
    height: auto;
    width: 100%;
    font-size: 25px;
    line-height: 23px;
    gap: 1rem;
    margin-bottom: 30px;
  }
}
