$first-section-width: 500px;

.forHimFirstSection {
  position: relative;
  width: $first-section-width;
  font-size: 54px;
  font-weight: 400;
  line-height: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    margin-top: 3rem;
  }
  span {
    font-family: DM Serif Display;
  }

  img {
    margin-top: 2rem;
    position: relative;
    width: 100% !important;
    height: 602.87px;
  }
}

.secondSection {
  width: 100% !important;
}

.navBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navItems {
  display: flex;
  align-items: center;
  gap: 2rem;
  span {
    color: var(--primary-color);
    position: relative;
    font-size: 20px;
    font-weight: 500;
    line-height: 29px;
    text-decoration: none;
    overflow: hidden;
    cursor: pointer;
    &::before {
      position: absolute;
      //   content: attr(data-content);
      top: 0;
      left: 0;
      color: var(--primary-color);
      //   text-decoration: underline;
      overflow: hidden;
      transition: width 300ms ease-in-out;
      width: 0;
      white-space: nowrap;
    }
    &:hover::before {
      width: 100%;
    }
    &::after {
      display: inline-block;
      content: "";
      width: 0%;
      height: 2px;
      background-color: var(--primary-color);
      transition: width 300ms ease-in-out;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    &:hover::after {
      width: 100%;
    }
  }
}

.viewAllBtn {
  height: 48px !important;
  width: 173px !important;
  border-radius: 0 !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 20px !important;
  text-transform: none !important;
  box-shadow: none !important;
  background: #ffffff !important;
  border: 1px solid #000000 !important;
  color: var(--primary-color-dark) !important;
}

.activeNav {
  color: var(--primary-color-dark) !important;
  &::after {
    display: inline-block !important;
    content: "" !important;
    width: 100% !important;
    height: 2px !important;
    background-color: var(--primary-color-dark) !important;
    position: absolute !important;
    bottom: 0 !important;
    left: 0 !important;
  }
}

.mainContainer {
  position: relative;
  width: 100%;
  display: flex;
  gap: 16px;
  // background-color: var(--primary-color-dark);
}

@media screen and (max-width: 1235px) {
  .forHimFirstSection {
    width: 100%;
    img {
      height: 430px;
    }
    p {
      margin: 0;
    }
  }
  .mainContainer {
    flex-direction: column;
  }
}

@media screen and (max-width: 835px) {
  .forHimFirstSection {
    p {
      font-size: 44px;
      font-weight: 400;
    }
  }

  .viewAllBtn {
    font-size: 11px !important;
  }
}

@media screen and (max-width: 550px) {
  .forHimFirstSection {
    > p {
      font-size: 25px;
    }

    > img {
      height: 200px;
    }
  }
  .navItems {
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
    span {
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.02em;
    }
  }
}
