.sliderBtnDiv {
  margin-top: 2rem;
  display: flex;
  gap: 2rem;
  .sliderBtn {
    background: none;
    border: none;
  }
}

.iconBtn {
  border: 1px solid #fff !important;
  color: #fff !important;
}
