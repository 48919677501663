.container {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
}

.text {
  font-family: DM Serif Display;
  font-size: 34px;
  font-weight: 400;
  line-height: 48px;
  text-align: center;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  opacity: 0;
  font-size: 40px;
  position: absolute;
  top: 0;
  left: 0;
  color: #ffffff;
  background-color: rgba(57, 45, 22, 0.85);
  width: 100%;
  height: 100%;
  -webkit-transition: all 400ms ease-out;
  -moz-transition: all 400ms ease-out;
  -o-transition: all 400ms ease-out;
  -ms-transition: all 400ms ease-out;
  transition: all 400ms ease-out;
  //   text-align: center;
}

.container .content:hover {
  opacity: 1;
}

.container .content .text {
  //   height: 0;
  //   opacity: 1;
  //   transition-delay: 0s;
  //   transition-duration: 0.4s;
}

// .container .content:hover .text {
//   opacity: 1;
//   transform: translateY(150px);
//   -webkit-transform: translateY(150px);
// }

.shopNowBtn {
  font-family: Inter !important;
  font-size: 11px !important;
  line-height: 16px !important;
  height: 48px !important;
  width: 173px !important;
  border-radius: 5 !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 20px !important;
  text-transform: uppercase !important;
  box-shadow: none !important;
  background: transparent !important;
  border: 1px solid #ffffff !important;
  color: #fff !important;
}
