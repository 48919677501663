.gridContainer {
  min-height: calc(100vh - 129px) !important;
}

@media screen and (max-width: 835px) {
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 550px) {
}
