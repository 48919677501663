.section {
  position: relative;
  width: 100%;
  display: flex;
  padding: 0 112px;
  margin: 49px 0;
}

.imgDiv {
  img {
    height: 100%;
    box-sizing: border-box;
    width: 100%;
  }
}

// .section > div:first-child {
//   display: grid;
//   place-items: center;
// }
.section > div:last-child {
  display: flex;
  flex-direction: column;
  gap: 26px;
  align-items: center;
}

.section > * {
  width: 100%;
}

.tabsDiv {
  height: 50px;
  width: 457px;
  display: flex;
  justify-content: center;
}

.tabButton {
  height: 44px;
  width: 50%;
  border-radius: 5px !important;
  text-transform: none !important;
  color: #857250 !important;
  background: #ffffff !important;
  box-shadow: none !important;
  border: 1px solid #857250 !important;
  font-family: DM Sans !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 20px !important;
}

.btn1 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.btn2 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.buttonActive {
  color: #ffffff !important;
  background: #6a5b40 !important;
}

.tabsHeader {
  font-size: 22px;
  font-weight: 700;
  line-height: 20px;
}

.accordionDiv {
  width: 457px;
  // padding: 21px 24px;
  background: #e0e0e0f2;
  border-radius: 5px;
  border: 1px solid #e0e0e0f2;
}

.accordionHeader {
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
}

.accordionSummary {
  background-color: #e0e0e0f2 !important;
  min-height: 55px !important;
}

.submitBtn {
  height: 48px !important;
  width: 330px !important;
  border-radius: 5px !important;
  text-transform: none !important;
  color: #fff !important;
  background: #857250 !important;
  box-shadow: none !important;
  border: 1px solid #857250 !important;
  font-family: DM Sans !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 20px !important;
}

@media screen and (max-width: 835px) {
  .section {
    padding: 0 56px;
    flex-direction: column;
  }
  .accordionDiv {
    width: 100%;
  }

  .submitBtn {
    width: 330px !important;
  }

  .tabsDiv {
    width: 100% !important;
    padding: 0 85px;
  }

  .section > div:last-child {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .section {
    padding: 0 100px !important;
  }
}

@media screen and (max-width: 550px) {
  .section {
    padding: 0 !important;
  }
  .section > div:last-child {
    padding: 1rem;
  }
  .submitBtn {
    width: 95% !important;
  }
  .tabsDiv {
    padding: 0;
  }
}
