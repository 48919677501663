.header {
  display: flex;
  font-size: 54px;
  font-weight: 400;
  line-height: 54px;
  letter-spacing: -1.5px;
  span:first-child {
    font-family: DM Serif Display;
    font-style: italic;
  }
  span:last-child {
  }
}

.carouselNavigationDiv {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  > a:first-child {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: #0a0a0a;
  }
}

.sliderBtnDiv {
  display: flex;
  gap: 1rem;
  .sliderBtn {
    background: none;
    border: none;
  }
}

.iconBtn {
  border: 1px solid rgb(0, 0, 0) !important;
  color: rgb(0, 0, 0) !important;
}

@media screen and (max-width: 835px) {
  .header {
    justify-content: center;
    font-size: 44px;
    line-height: 44px;
    text-align: center;
  }
}

@media screen and (max-width: 550px) {
  .header {
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.5px;
  }
}
