.container {
  border-bottom: 1px solid var(--primary-color);
  border-left: 1px solid var(--primary-color);
  border-right: 1px solid var(--primary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  > div {
    display: grid;
    gap: 1rem;
    padding: 1rem 1.5rem;
    width: 650px;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.09);
    border-radius: 15px;
    > span:first-child {
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
    }
    > div {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      > div {
        display: flex;
        align-items: center;
        gap: 2rem;
        > img {
          width: 169px;
          height: 100px;
          border-radius: 10px;
        }
        > div {
          display: grid;
          gap: 0.3rem;
          > p:first-child {
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
          }
          > p:last-child {
            display: flex;
            gap: 3rem;
            > span:first-child {
              font-size: 16px;
              font-weight: 400;
              line-height: 22px;
              color: grey;
            }
            > span:last-child {
              font-size: 16px;
              font-weight: 700;
              line-height: 22px;
              color: var(--primary-color);
            }
          }
        }
      }
    }
  }
}
