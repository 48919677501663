.section {
  display: flex;
  width: 100%;
  box-sizing: border-box;
}

.cardsDivOuterContainer {
  padding-left: 4rem;
}

.headerDiv {
  width: 70%;
  display: flex;
  align-items: center;
  gap: 31px;
  margin-top: 40px;
  .header {
    font-family: DM Serif Display;
    font-size: 34px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0.03em;
    color: #857250;
  }
}

.cardsDiv {
  margin-top: 17px;
  display: grid;
  gap: 25px;
}

@media screen and (max-width: 835px) {
  .cardsDivOuterContainer {
    padding: 30px;
  }
}

@media screen and (max-width: 550px) {
  .cardsDivOuterContainer {
    padding: 1rem;
  }
}
