.container {
  margin: 27px 0 !important;
  width: 100% !important;
}

.header {
  margin: 2rem 0 1rem 0;
  font-family: DM Serif Display;
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
}

.gridItemLeft {
  display: flex;
  justify-content: flex-end;
}

.gridItemRight {
  display: flex;
  justify-content: flex-start;
}

.buttonDiv {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
  gap: 2rem;
}

.button {
  height: 54px !important;
  width: 330px !important;
  border-radius: 5px !important;
  padding: 12px, 16px, 12px, 16px !important;
  color: #857250 !important;
  border: 0.5px solid #9d8e73 !important;
  box-shadow: none !important;
  background-color: transparent !important;
  font-family: DM Sans !important;
  font-size: 15px !important;
  font-weight: 700 !important;
  line-height: 20px !important;
  text-transform: none !important;
}

.buttonActive {
  background: #857250 !important;
  color: #fff !important;
}

@media screen and (max-width: 950px) {
  .gridItemLeft,
  .gridItemRight {
    justify-content: center !important;
  }
}

@media screen and (max-width: 950px) {
  .header {
    text-align: center;
  }
}

@media screen and (max-width: 550px) {
  .buttonDiv {
    gap: 1rem;
    margin-top: 4rem !important;
    margin-bottom: 2rem !important;
  }
}
