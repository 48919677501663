@mixin btn {
  text-transform: none !important;
  font-family: DM Sans !important;
  box-shadow: none !important;
  border-radius: 5px !important;
  border: none !important;
  outline: none !important;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;

  .firstContainer {
    width: 489px;
    border: 1px solid var(--primary-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    > div:first-child {
      width: 100%;
      height: 63px;
      background: rgba(230, 227, 220, 1);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    > div:nth-child(2) {
      //   height: 201px;
      width: 382px;
      border: 1px solid var(--primary-color);
      > div:first-child {
        padding: 1rem;
        display: grid;
        gap: 0.6rem;
        > div:first-child {
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
          }
          > div {
            height: 28px;
            width: 60px;
            border-radius: 100px;
            display: grid;
            place-items: center;
            background: rgba(230, 227, 220, 1);
            font-size: 12px;
            font-weight: 700;
            line-height: 16px;
            letter-spacing: 0.3199999928474426px;
            color: var(--primary-color-dark);
          }
        }
        > p:nth-child(2) {
          font-size: 15px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0.1599999964237213px;
          color: rgba(108, 108, 108, 1);
        }
        > p:last-child {
          > span:first-child {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.1599999964237213px;
          }

          > span:last-child {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.1599999964237213px;
            color: var(--primary-color);
          }
        }
      }

      > div:last-child {
        margin-top: auto;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid rgba(206, 206, 206, 1);
      }
    }
  }
}

.editBtn,
.changeBtn {
  height: 42px !important;
  flex: 1 !important;
  background: transparent !important;
  text-transform: none !important;
  font-family: DM Sans !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 20px !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  border: none !important;
  outline: none !important;
}
.editBtn {
  color: var(--primary-color) !important;
  border-right: 1px solid rgba(206, 206, 206, 1) !important;
}
.changeBtn {
  color: rgba(218, 30, 40, 1) !important;
}

.btnDiv {
  .addNewAddressBtn,
  .useCurrentLocationBtn {
    height: 56px !important;
    width: 330px !important;
    @include btn();
    background-color: var(--primary-color-dark) !important;
    color: #fff !important;
  }
}

.secondContainer {
  padding: 2rem;
  width: 489px;
  border: 1px solid var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    height: 203px;
    width: 385px;
    border-radius: 19px;
    // background-color: var(--primary-color);
    background: rgba(133, 114, 80, 1);

    padding: 23px 29px;
    color: #fff;
    display: grid;
    justify-content: center;
    text-align: center;
    box-shadow: 0px 1px 56px 0px rgba(0, 0, 0, 0.23);

    > span:first-child {
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
    }
    > p:nth-child(2) {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.3199999928474426px;
    }

    .recommendedBtnDiv {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      .yesBtn,
      .noBtn {
        @include btn();
        flex: 1 !important;
        height: 35px !important;
      }

      .yesBtn {
        color: var(--primary-color) !important;
        background-color: #fff !important;
      }
      .noBtn {
        border: 1px solid #fff !important;
        background-color: transparent !important;
        color: #fff !important;
      }
    }
  }
}

.lastContainer {
  margin: 1rem 0 5rem;
  .proceedBtn {
    height: 58px !important;
    width: 330px !important;
    @include btn();
    color: #fff !important;
    background-color: var(--primary-color-dark) !important;
  }
}

.dialog {
  display: grid;
  .dialogFirstContainer {
    padding: 0 2rem;
    width: 100%;
    display: grid;
    gap: 1rem;
    > div:first-child {
      display: flex;
      align-items: center;
      justify-content: space-between;
      > span:first-child {
        font-family: DM Serif Display;
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
      }
    }

    > div:nth-child(3) {
      display: flex;
      align-items: center;
      gap: 2rem;
      > div:first-child {
        border: 1px solid var(--primary-color);
        border-radius: 5px;
        height: 37px;
        width: 376px;
        display: flex;
        align-items: center;

        > input {
          height: 30px;
          border: none;
          outline: none;
          background-color: transparent;
          padding: 5px 8px;
          flex: 1;
        }
        .searchBtn {
          @include btn();
          background-color: rgba(133, 114, 80, 1) !important;
          color: #fff !important;
          height: 37px !important;
          width: 130px !important;
          border-radius: 5px !important;
        }
      }
    }

    > div:nth-child(4) {
      margin-top: 2rem;
      display: flex;
      justify-content: space-between;
    }

    > div:last-child {
      display: flex;
      justify-content: center;
      margin: 2rem 0 4rem 0;

      .loadBtn {
        @include btn();
        background-color: var(--primary-color-dark) !important;
        color: #fff !important;
        font-family: Inter !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        line-height: 24px !important;
        height: 42px !important;
        width: 150px !important;
      }
    }
  }

  .dialogLastContainer {
    padding: 2rem;
    display: grid;
    gap: 1rem;
    > span:first-child {
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
    }
    > span:nth-child(2) {
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      color: rgba(59, 59, 59, 1);
    }
  }
}

.filterBtn {
  @include btn();
  background-color: rgba(133, 114, 80, 1) !important;
  color: #fff !important;
  height: 100% !important;
  width: 81px !important;
  border-radius: 5px !important;
}

.accordion {
  border: 1px solid var(--primary-color) !important;
  width: 588px !important;

  .accordionSummaryDiv {
    display: flex;
    align-items: center;

    > div:last-child {
      display: flex;
      gap: 10px;
      font-weight: 700;
      > span:first-child {
        font-size: 16px;
        line-height: 20px;
      }
      > span:last-child {
        font-size: 14px;
        line-height: 18px;
        color: rgba(59, 59, 59, 1);
      }
    }
  }
  .accrodionDetailsDiv {
    display: grid;
    gap: 0.5rem;
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.1599999964237213px;
      display: flex;
      align-items: center;
      gap: 5px;

      &::before {
        content: "";
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: #857250;
        display: inline-block;
      }
    }
    > p:last-child {
      a {
        color: var(--primary-color);
      }
    }
  }
}

.dialogProceedBtnDiv {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  .dialogProceedBtn {
    @include btn();
    height: 58px !important;
    width: 330px !important;
    color: #fff !important;
    background-color: var(--primary-color-dark) !important;
  }
}

.dialogPopularDiv {
  display: grid;
  gap: 1rem;
  > span:first-child {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: rgba(59, 59, 59, 1);
  }
}

@media screen and (max-width: 835px) {
  .accordion {
    width: 100% !important;
  }
}

@media screen and (max-width: 550px) {
  .container {
    .firstContainer {
      width: 100%;
      > div:nth-child(2) {
        width: 90%;
      }
    }
  }

  .btnDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .addNewAddressBtn,
    .useCurrentLocationBtn {
      height: 56px !important;
      width: 90% !important;
    }
  }

  .secondContainer {
    padding: 1rem;
    width: 100%;
    > div {
      width: 100%;
    }
  }

  .dialog {
    .dialogFirstContainer {
      padding: 0.8rem 0.5rem;
      > div:first-child {
        flex-direction: column;
        gap: 0.5rem;
        > div:first-child {
          width: 100%;
          display: flex;
          justify-content: flex-end;
        }
        > div:last-child {
          width: 100%;
          display: flex;
          justify-content: space-between;
          > span:first-child {
            font-family: DM Serif Display;
            font-size: 16px;
            font-weight: 400;
            line-height: 28px;
          }
        }
      }

      > div:nth-child(3) {
        > div:first-child {
          height: 37px;
          width: 100%;
          .searchBtn {
            width: auto !important;
          }
        }
      }

      > div:nth-child(4) {
        margin-top: 2rem;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
      }
    }
  }

  .dialogProceedBtnDiv {
    margin-top: 2rem;
    .dialogProceedBtn {
      width: 100% !important;
    }
  }

  .dialogLastContainer {
    padding: 1rem !important;
    gap: 0.7rem !important;
    > span:first-child {
      font-size: 16px !important;
      line-height: 20px !important;
    }
    > span:nth-child(2) {
      font-size: 12px !important;
      line-height: 16px !important;
    }
  }
}
