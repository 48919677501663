.container {
  display: grid;
  gap: 0.5rem;
}

.label {
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1599999964237213px;
  text-align: left;
}

.input {
  border: 1px solid #6a5b40;
  height: 48px;
  width: 360px;
  border-radius: 5px;
  padding: 15px;
  outline: none;
  &::placeholder {
    color: #9d9d9d;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.1599999964237213px;
  }
}

@media screen and (max-width: 768px) {
  .input {
    width: 100%;
  }
}
