.btn {
  text-transform: none !important;
//   width: 84px !important;
  background-color: #fff !important;
}

.filterBtn {
  font-family: DM Serif Display !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 28px !important;
}

.clearBtn {
  font-family: DM Sans !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  letter-spacing: 0.1599999964237213px;
  color: var(--primary-color) !important;
}

@media screen and (max-width: 550px) {
  .btn {
    height: 37px !important;
    // width: 70px !important;
  }
  .filterBtn {
    font-size: 14px !important;
    line-height: 19px !important;
  }

  .clearBtn {
    font-size: 12px !important;
    line-height: 16px !important;
  }
}
