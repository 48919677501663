.container {
  width: 100%;
  background-image: url(https://www.figma.com/file/3feKLdzH2SEin23kTS0pjx/image/929594f20fb77a2fd6e27e435ff36cf47fb781e4);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 500px;
  div {
    padding: 0 131px;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      #6a5b40 0%,
      rgba(106, 91, 64, 0.659619) 34.95%,
      rgba(106, 91, 64, 0.409822) 59.05%,
      rgba(106, 91, 64, 0.05) 72.93%,
      rgba(106, 91, 64, 0) 106.75%
    );

    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
  }
  color: #fff;
  .header {
    font-family: DM Serif Display;
    font-size: 92px;
    font-weight: 400;
    line-height: 92px;
    letter-spacing: -1.5px;
    text-align: left;
  }
}

.btn {
  height: 48px !important;
  width: 201px !important;
  border-radius: 0px !important;
  background-color: #fff !important;
  text-transform: uppercase !important;
  font-family: Inter !important;
  font-size: 11px !important;
  font-weight: 700 !important;
  line-height: 16px !important;
  color: rgb(0, 0, 0) !important;
  border: 1px solid var(--primary-color) !important;
  margin-top: 3rem !important;
  border: none !important;
  box-shadow: none !important;
}

@media screen and (max-width: 835px) {
  .header {
    font-size: 62px !important;
  }
}

@media screen and (max-width: 550px) {
  .container {
    height: 342px;
    div {
      padding: 0 1rem;
    }
    .header {
      font-size: 35px !important;
      line-height: 40px;
    }
  }
}
