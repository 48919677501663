.header {
  display: flex;
  align-items: center;
  gap: 2rem;
  font-family: DM Serif Display;
  font-size: 54px;
  font-weight: 400;
  line-height: 64px;
  margin-bottom: 3rem;
  color: #ffffff;
}

.carouselNavigationDiv {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  > a:first-child {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: #ffffff;
  }
}

.sliderBtnDiv {
  display: flex;
  gap: 1rem;
  .sliderBtn {
    background: none;
    border: none;
  }
}

.iconBtn {
  border: 1px solid rgb(0, 0, 0) !important;
  color: rgb(0, 0, 0) !important;
}

@media screen and (max-width: 835px) {
  .header {
    font-size: 44px;
  }
}
@media screen and (max-width: 550px) {
  .header {
    font-size: 25px;
    line-height: 28px;
    gap: 0.5rem;
  }
}
