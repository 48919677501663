.container {
  padding: 19px 19px 0 40px;
  background: #e6e3dc;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 320px;
  // width: 354px;
  min-width: 310px;

  .breadcrumbDiv {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .profileDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    > img {
      min-height: 60px;
      min-width: 60px;
      max-height: 60px;
      max-width: 60px;
      border-radius: 50%;
    }
    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      span {
        display: flex;
      }
      > span:first-child {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
      }

      > span:last-child {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.1599999964237213px;
      }
    }
  }
}

.navItemsDiv {
  display: flex;
  flex-direction: column;
  gap: 1.1rem;
  margin-bottom: 5rem;
  .navItem {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}

@media screen and (max-width: 930px) {
  .container {
    padding: 19px;
  }
}
