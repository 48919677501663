.container {
  position: relative;
  background: #fff;
  width: 360px;
  > img {
    width: 100%;
    height: 225px;
  }

  .productDetailsDiv {
    position: relative;
    padding: 1rem;
    display: grid;
    gap: 0.3rem;
    > img {
      position: absolute;
      right: 5%;
      transform: translateY(-40%);
    }
    > span:nth-child(2) {
      color: var(--primary-color);
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0.3199999928474426px;
    }
    > span:nth-child(3) {
      font-size: 18px;
      font-weight: 500;
      line-height: 23px;
    }
    p:nth-child(4) {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.1599999964237213px;
      color: #718096;
    }
    a {
      margin-top: 40px;
      text-decoration: underline;
      color: var(--primary-color-dark);
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0.3199999928474426px;
    }
  }
}

@media screen and (max-width: 550px) {
  .container {
    width: 100%;
  }
}
