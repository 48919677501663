.section {
  padding: 0 131px;
}

.header {
  margin-top: 35px;
  font-family: DM Serif Display;
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
}

.sizesDiv {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}

.sizeButton {
  border: 1px solid #cecece;
  color: #cecece;
  border-radius: 50%;
  height: 63px !important;
  width: 63px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}

.sizeButtonActive {
  color: #fff;
  border: 1px solid #857250;
  background: #857250;
}

.sizeIllustrationDiv {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 33px;
  width: 100%;
  height: 320px;
  // overflow-x: scroll;
  // ::-webkit-scrollbar {
  //   display: none;
  // }
  // -ms-overflow-style: none; /* IE 11 */
  // scrollbar-width: none; /* Firefox 64 */
}

.buttonDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 5rem;
  .button {
    height: 45px;
    flex-shrink: 0.5;
    width: 260px;
    border-radius: 5px;
    font-family: DM Sans !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    text-transform: none !important;
    color: #ffffff !important;
    background: #857250 !important;
    box-shadow: none !important;
  }
}

@media screen and (max-width: 835px) {
  .section {
    padding: 0;
  }
  .header {
    margin-top: 30px;
  }

  .buttonDiv {
    margin-top: 10px;
  }

  .sizeIllustrationDiv {
    margin-top: 7px;
  }
}

@media screen and (max-width: 768px) {
  .section {
    padding: 0;
  }

  .buttonDiv {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 550px) {
  .sizeButton {
    height: 48px !important;
    width: 48px !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
  }

  .sizeIllustrationDiv {
    margin-top: 50px;
  }

  .buttonDiv {
    margin: 0 0 50px;
  }
}
