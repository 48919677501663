.section {
  display: flex;
  width: 100%;
  box-sizing: border-box;
}

.cardsDivOuterContainer {
  // width: calc;
  flex: 1;
  position: relative;
  padding-left: 4rem;
  padding-right: 112px;
}

.headerDiv {
  width: 70%;
  display: flex;
  align-items: center;
  gap: 31px;
  margin-top: 40px;
  .header {
    font-family: DM Serif Display;
    font-size: 34px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0.03em;
    color: #857250;
  }
}

.ordersCardDiv {
  width: 60%;
  margin-top: 2rem;
  > div:first-child {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

.deliveryAddress {
  margin: 0.7rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  > span:first-child {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }
  > p:nth-child(2) {
    display: flex;
    gap: 5px;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    > span:last-child {
      color: grey;
    }
  }

  > p:nth-child(3) {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #6c6c6c;
  }
  > p:last-child {
    display: flex;
    gap: 5px;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    > span:last-child {
      color: grey;
    }
  }
}

.pastOrdersDiv {
  margin: 2rem 0 5rem;
  > div:first-child {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 1220px) {
  .ordersCardDiv {
    width: 100%;
  }
}

@media screen and (max-width: 1918px) {
  .cardsDivOuterContainer {
    padding: 0 56px;
  }
}

@media screen and (max-width: 835px) {
  .cardsDivOuterContainer {
    padding: 30px;
  }

  .headerDiv {
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .cardsDivOuterContainer {
    padding: 1rem;
  }
}
