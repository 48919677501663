.carouselItem {
  width: 100%;
  background-image: url(https://s3-alpha-sig.figma.com/img/e0c5/9b62/217c0cbfc4e549ecbe7e3ab7a44b35d5?Expires=1626048000&Signature=Sj2yLEZsWIn6g7pj1IhSp4~4cBGqEAzyokddW76UWcp6XYS39GFkJoK3BWvq25MxFBe0tVuSRAwqlf4U5xHiNzo4hhSkO-EYr5Octm1DjImRlCogXtXymmdeHpvaYZarC1McsypmGlZGEroxhmHm~DfEXQbTHt7pC4PMcbKbpWgUHeNlm1gqB8bz0YZqaP3tR5Bd1OmpCTi4n3gbJXg5MabCo~-u06EpUZ9zJm1Gjc1FXwVHUo6hSQPIAUavi-Mzwvk~vJ81BQQgYRltwG2RDAEr1XsQtOnnrdGPONNcHuZnG83SJm1ZGwOnRUD0vR1IjkoXbZ5tdlOjvUhHooJnIw__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 484px;
  div {
    width: 100%;
    height: 100%;
    background: #392d16e5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  color: #fff;
  span {
    font-family: DM Serif Display;
    font-size: 92px;
    font-weight: 400;
    line-height: 92px;
    letter-spacing: -1.5px;
    text-align: center;
  }
  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.0075em;
    text-align: center;
    width: 60%;
    margin-top: 1rem;
  }
}

.btn {
  height: 48px !important;
  width: 201px !important;
  border-radius: 0px !important;
  background-color: transparent !important;
  text-transform: uppercase !important;
  font-family: Inter !important;
  font-size: 11px !important;
  font-weight: 700 !important;
  line-height: 16px !important;
  color: #fff !important;
  border: 1px solid #fff !important;
  margin-top: 3rem !important;
}
