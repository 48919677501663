.mainContainer {
  width: 100%;
}

.cardContainer {
  margin: 1rem 0;
  position: relative;
  width: 100%;
  height: 178px;
  display: flex;
  gap: 14px;
  > img:first-child {
    height: 100%;
    width: 165px;
  }
}

.detailsDiv {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .detailOne {
    width: 100%;
    display: flex;
    justify-content: space-between;
    > div:first-child {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 4px;
      > span:first-child {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
      }

      > span:last-child {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.3199999928474426px;
        color: #6c6c6c;
      }
    }
    > div:last-child {
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      color: #42be65;
    }
  }

  .detailTwo {
    > span:first-child {
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0.3199999928474426px;
      color: #3b3b3b;
    }
    > span:last-child {
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
    }
  }

  .detailThree {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > span:first-child {
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0.3199999928474426px;
      color: #6a5b40;
    }

    a {
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0.3199999928474426px;
      color: #da1e28;
      text-decoration: underline;
    }
  }
}

.rateBtn {
  //   height: 40px !important;
  //   width: 164px !important;
  padding: 0.5rem 1rem !important;
  border-radius: 5px;
  font-family: DM Sans !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  text-transform: none !important;
  background-color: #6a5b40 !important;
  color: #fff !important;
}

@media screen and (max-width: 550px) {
  .cardContainer {
    height: 255px;
  }

  .detailsDiv > .detailOne {
    > div:first-child {
      width: 100%;
    }
    > div:last-child {
      display: none;
    }
  }

  .rateBtn {
    font-size: 12px !important;
  }
}
