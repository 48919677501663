$primary-color: #6a5b40;
$text-secondary: #3b3b3b;

.gridContainer {
  width: 100% !important;
  margin: 0 !important;
  // gap: 1rem !important;
}

.linksHeader {
  font-family: DM Serif Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0.9rem;
}

.gridItem {
  display: flex !important;
  flex-direction: column !important;
  .linksContainer {
    display: flex !important;
    flex-direction: column !important;
    gap: 0.4rem;
  }
}

.logoContainer {
  .logo {
    color: $primary-color;
    font-family: DM Serif Display;
    font-size: 54px;
    font-weight: 400;
    line-height: 64px;
  }

  .emailContianer {
    margin-top: 24px;
    display: grid;
    span {
      font-family: DM Serif Display;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      color: #000;
    }
    label {
      font-family: DM Sans;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.1599999964237213px;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }

    input {
      box-sizing: border-box;
      width: 100%;
      height: 48px;
      border-radius: 5px;
      border: 1px solid $primary-color;
      padding: 1rem 0.8rem;
      margin-bottom: 1.4rem;
    }
  }
  .emailSubmitBtn {
    background: $primary-color !important;
    height: 48px !important;
    width: 173px !important;
    border-radius: 5px !important;
    font-family: Inter;
    font-size: 11px !important;
    font-weight: 700 !important;
    line-height: 16px !important;
    color: #fff !important;
  }
}

.talkToUsContainer {
  margin-top: 13px;
  div:first-child {
    display: flex;
    gap: 0.5rem;
    span {
      font-family: DM Serif Display;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
    }
  }

  div:last-child {
    margin-top: 0.5rem;
    color: #3b3b3b;
    display: grid;
    gap: 5px;
    span {
      padding-left: 2rem;
    }
  }
}

.mediaHandlerContainer {
  // display: flex;
  //   gap: 4rem;
  .socialMediaContainer {
    display: grid !important;
    div {
      display: flex !important;
    }
  }

  .storeContainer {
    display: grid;
    .storeImageContainer {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      flex-wrap: wrap;
      gap: 2rem;
      .googlePlay,
      .appStore {
        height: 66px;
        width: 219px;
      }

      .originalIconContainer {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        img {
          height: 46.98940658569336px;
          width: 50px;
        }
        div {
          display: grid;
          span:first-child {
            font-family: DM Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: left;
          }
          span:last-child {
            font-family: DM Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.1599999964237213px;
          }
        }
      }
    }
  }
}

.endTagline {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  color: $text-secondary;
  display: flex;
  justify-content: center;
}

.searchTagContainer {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
