.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  .header {
    font-family: DM Serif Display;
    font-size: 54px;
    font-weight: 400;
    line-height: 54px;
    letter-spacing: -1.5px;
    color: #fff;
  }
  > div {
    display: grid;
    place-items: center;
    > input {
      height: 48px;
      width: 740px;
      border-radius: 5px;
      border: 1px solid rgba(255, 255, 255, 1);
      padding: 1rem 1.3rem;
      background-color: transparent;
      color: #fff;
      outline: none;
      &::placeholder {
        color: #fff;
      }
    }
  }
}

.btn {
  height: 48px !important;
  width: 201px !important;
  border-radius: 5px !important;
  background-color: #fff !important;
  font-family: Inter !important;
  font-size: 11px !important;
  font-weight: 700 !important;
  line-height: 16px !important;
  color: rgb(0, 0, 0) !important;
  border: 1px solid var(--primary-color) !important;
  margin-top: 3rem !important;
  border: none !important;
  box-shadow: none !important;
}
