.container {
  height: 238px;
  width: 100%;
  border-radius: 5px;
  background: #857250;
  padding: 10px;
  .innerContainer {
    width: 100%;
    height: 100%;
    border: 1px solid #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    img {
      height: 80%;
    }
    div {
      margin-top: 1rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1rem;
      padding: 0.5rem;
      color: #fff;
      .header {
        font-family: DM Serif Display;
        font-size: 35px;
        font-weight: 400;
        line-height: 48px;
        text-align: left;
      }
      .ShopNowBtn {
        height: 38px !important;
        // width: 147px !important;
        max-width: 147px !important;
        min-width: 80px !important;
        font-family: DM Sans !important;
        font-size: 16px !important;
        font-weight: 500 !important;
        line-height: 21px !important;
        color: #857250 !important;
        border-radius: 0 !important;
        background: #fff !important;
        // border: solid !important;
      }
    }
  }
}

@media screen and (max-width: 550px) {
}
