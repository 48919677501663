.container {
  height: auto;
  width: 288px;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 3px;
  margin-bottom: 50px;
  img {
    width: 100%;
    height: 350px;
  }
}

.trends {
  color: var(--primary-color-dark);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  text-align: center;
  > span:first-child {
    font-family: DM Serif Display;
    font-size: 26px;
    font-weight: 400;
    line-height: 36px;
  }

  > span:last-child {
    font-size: 25px;
    font-weight: 700;
    line-height: 20px;
  }
}

@media screen and (max-width: 835px) {
  .container {
    width: 225px;
    img {
      height: 366px;
    }
  }
  .trends {
    gap: 0;
    padding: 0.3rem;
    > span:first-child {
      font-size: 20px;
      line-height: 36px;
    }
    > span:last-child {
      font-size: 20px;
      line-height: 20px;
    }
  }
}
@media screen and (max-width: 768px) {
  .container {
    img {
      height: 315px;
    }
  }
}
@media screen and (max-width: 550px) {
  .container {
    width: 100%;
    margin-bottom: 34px;
    img {
      height: 268px;
    }
  }
  .trends {
    > span:first-child {
      line-height: 20px;
      font-size: 14px;
    }
    > span:last-child {
      font-size: 14px;
    }
  }
}
