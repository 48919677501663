.container {
  position: relative;
  width: 100%;
  display: flex;
  padding: 3rem 0;

  .firstSection {
    width: 20%;
    border-right: 1px solid rgb(236, 236, 236);
  }

  .secondSection {
    width: 80%;
  }
}

@media screen and (max-width: 835px) {
  .container {
    padding-top: 0;
    .secondSection {
      width: 100%;
    }
  }
}
