.section {
  position: relative;
  padding: 0 131px;
}

.header {
  margin-top: 1rem;
  font-family: DM Serif Display;
  font-size: 34px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 0.03em;
  text-align: left;
}

.buttonDiv {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.nextButton {
  height: 48px;
  width: 360px !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 20px !important;
  background: #6a5b40 !important;
  text-transform: none !important;
  color: #ffffff !important;
  box-shadow: none !important;
}

.genderSelectorDiv {
  height: 514px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.genderContainer {
  display: flex;
  gap: 94px;
  margin-top: 10px;
  margin-bottom: 96px;
}

.genderContainer > * {
  width: 100%;
}

.inputFieldDiv {
  display: grid;
  gap: 1rem;
  margin: 28px 0 37px 0;
}

.inputFieldDiv > div {
  display: grid;
  gap: 1rem;
}

.detailsHeader {
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.05em;
}

@media screen and (max-width: 768px) {
  .header {
    text-align: center;
  }
  .section {
    padding: 0 29px;
  }
  .genderContainer {
    flex-direction: column;
  }

  .buttonDiv {
    justify-content: center;
  }

  .inputFieldDiv > div {
    width: 100%;
    display: flex;
  }

  .inputFieldDiv > div > * {
    width: 100%;
  }
  .detailsHeader {
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 25px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: -0.05em;
  }
}

@media screen and (max-width: 550px) {
  .section {
    padding: 0;
  }
  .detailsDiv {
    display: grid;
  }

  .inputFieldDiv > div {
    display: grid;
  }

  .detailsDiv {
    padding: 0 1rem;
  }
  .detailsHeader {
    font-size: 20px;
    line-height: 20px;
  }
  .nextButton {
    width: 100% !important;
  }
}
