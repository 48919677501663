.container {
  padding: 35px;
  border: 1px solid #857250;
  height: 203px;
  width: 385px;
  border-radius: 19px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .bearerInfoDiv {
    display: flex;
    justify-content: space-between;
  }
  .bearerInfoFirstDiv {
    display: flex;
    flex-direction: column;
    .bearer {
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
    }
    .bearerBasicInfo {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.3199999928474426px;
    }
  }
}
.completionPreview {
  height: 80px;
  width: 80px;
}

.buttonDiv {
  display: flex;
  justify-content: center;
  .button {
    border: 1px solid #857250 !important;
    height: 35px !important;
    width: 150px !important;
    border-radius: 5px !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 28px !important;
    text-transform: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }
}
