.cardContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  .firstCon,
  .secondCon {
    position: relative;
    max-height: 260px;
    width: 100% !important;
    display: flex;
    gap: 1rem;
  }
  //   width: 590px;
}

.firstCon {
  > div:first-child {
    width: calc(100% / 2);
  }
}

.secondCon {
  > div:last-child {
    width: calc(100% / 2);
  }
}

.header {
  display: flex;
  margin-bottom: 30px;
  gap: 1rem;
  font-size: 54px;
  line-height: 54px;
  font-weight: 400;
  letter-spacing: -1.5px;
  span:first-child {
    font-style: italic;
    font-family: DM Serif Display;
  }
  span:last-child {
  }
}

@media screen and (max-width: 835px) {
  .header {
    font-size: 44px !important;
    line-height: 44px !important;
    justify-content: center;
  }
  .firstCon,
  .secondCon {
    max-height: 250px !important;
  }

  .firstCon {
    > div:first-child {
      width: 120% !important;
    }
  }

  .secondCon {
    > div:last-child {
      width: 120% !important;
    }
  }
}

@media screen and (max-width: 768px) {
}
@media screen and (max-width: 550px) {
  .header {
    font-size: 25px !important;
    line-height: 20px !important;
  }
}
