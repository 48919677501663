.mainContainer {
  background-image: url(https://s3-alpha-sig.figma.com/img/e176/3bfe/f781977b2c342c506822885fd9cd1294?Expires=1627862400&Signature=S9Fy1jeMQLXkezz~zRSmmcqXf0lZ~84L51sHFuV0HvDzQy7AMdZ2UwrmHDd6btDqPz64TtRut3fpxDHwxq2Exi3ZpMx8WX9CsRHrRRYfRs0YhbvO5LwZBpurD~CzuSBhWbjL6Rj5T4fBlAPVgLCqTz1abaAlXph4BXcahXrN67BUfN0lzgJP1KYmZr1V4s7PdZNQUe8SfpotaUmti1s94FYNAhAMKcwpr7V8rtYXGh0W~ViBS93LcZamIF0P73aYvzMOknaSAFGutHcm8L4TnMg1Lggg-kh3qcUiH1a518TXY~rbBA1wN3~EmFeMPwYTkpgHkxA4Wqd1pXd10yO1FA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.container {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    radial-gradient(
      79.03% 79.03% at 54.08% 43.43%,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.43) 100%
    );

  height: 380px;
  display: flex;
  align-items: center;
  gap: 3rem;
  padding: 3rem 3rem;
  //   background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.6)),
  //     radial-gradient(
  //       79.03% 79.03% at 54.08% 43.43%,
  //       rgba(0, 0, 0, 0) 0%,
  //       rgba(0, 0, 0, 0.43) 100%
  //     );
}

.imgContainer {
  height: 298px;
  min-width: 300px;
  max-width: 300px;
  border-radius: 18px;
  border: 10px solid rgba(133, 114, 80, 1);
  background: rgba(133, 114, 80, 1);

  img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
  }
}

.detailsDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  > span:first-child {
    font-family: DM Serif Display;
    font-size: 61px;
    font-weight: 400;
    line-height: 92px;
    letter-spacing: 0.006em;
  }
  > span:last-child {
    font-size: 18px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
  }
}

@media screen and (max-width: 835px) {
  .container {
    padding: 0rem 2rem;
  }

  .detailsDiv {
    > span:first-child {
      font-size: 51px;
      line-height: 41px;
    }
    > span:last-child {
      font-size: 16px;
      font-weight: 400;
      line-height: 29px;
    }
  }
}

@media screen and (max-width: 550px) {
  .container {
    height: 400px;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 1rem;
    gap: 1rem;
  }

  .imgContainer {
    height: 195px;
    min-width: 207px;
    max-width: 207px;
    border-width: 6px;
  }

  .detailsDiv {
    > span:first-child {
      font-size: 30px;
      // line-height: 45px;
      text-align: center;
    }
    > span:last-child {
      font-size: 12px;
      line-height: 22px;
      text-align: center;
    }
  }
}
